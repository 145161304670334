<template>
  <v-card outlined class="ma-4">

    <!-- header -->
    <v-toolbar flat dense color="primary">
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer/>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined solo v-bind="attrs" v-on="on" style="text-transform: unset">
            Klasyfikacja
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(ranking, index) in rankings" :key="index" @click="changeOption(index)">
            <v-list-item-title>{{ ranking['name'] }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>

    <v-tabs :vertical="$vuetify.breakpoint.mdAndUp" show-arrows color="primary" v-if="state === 'ok'" v-model="tab">

      <!-- navigation -->
      <v-tab v-for="(category, index) in rankings[option]['categories']" :key="index">
        {{ category['name'] }}
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp"/>
      </v-tab>

      <!-- rankings -->
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item v-for="(category, index) in rankings[option]['categories']" :key="index"
                    :class="'border-left-' + ($vuetify.theme.dark ? 'dark' : 'light')">
          <!-- TODO dynamically calculate min-height -->
          <v-card flat min-height="600">
            <v-card-text>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-right">#</th>
                    <th class="text-left">Zawodnik</th>
                    <th class="text-center" v-for="label in category['labels']" :key="label">
                      <v-icon v-if="category['icons']">{{ $utils.events.getEventIcon(label) }}</v-icon>
                      {{ category['icons'] ? '' : label }}
                    </th>
                    <th class="text-center font-weight-bold">Suma</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(rank, index) in category['ranking']" v-bind:key="index">
                    <td class="text-right">{{ rank.place }}</td>
                    <td class="text-left">
                      {{ rank.name }}&nbsp;&nbsp;
                      <span v-if="rank.change != null && rank.change > 0" style="color: #4CAF50">
                      {{ '+ ' + rank.change }}
                    </span>
                      <span v-else-if="rank.change != null && rank.change < 0" style="color: #F44336">
                      {{ '- ' + (-rank.change) }}
                    </span>
                      <v-icon v-if="rank.change > 0" color="green">mdi-arrow-top-right-thin</v-icon>
                      <v-icon v-else-if="rank.change < 0" color="red">mdi-arrow-bottom-right-thin</v-icon>
                      <v-icon v-else-if="rank.change === 0" color="yellow darken-2">mdi-arrow-right-thin</v-icon>
                    </td>
                    <td class="text-center" v-for="(point, index) in rank['points']" :key="index">
                      {{ point }}
                    </td>
                    <td class="text-center font-weight-bold primary--text">{{ rank['overall'] }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

    </v-tabs>

    <!-- loading -->
    <v-skeleton-loader v-if="state === 'loading'" type="card"/>

    <!-- error -->
    <v-alert v-if="state === 'error'">
      <v-icon left color="error">mdi-alert-circle-outline</v-icon>
      Wystąpił nieoczekiwany błąd. Spróbuj odświeżyć tę stronę.
    </v-alert>

  </v-card>
</template>

<script>
export default {
  name: 'Ranking',
  data: () => ({
    state: 'loading', title: '', tab: 0,
    series: {series_id: '', series_name: '', live_comp: '', points: [1]},
    categories: [], competitions: [], rankings: [], option: 0
  }),
  created() {
    this.$http.post('/api/series', {id: this.$route.params.id}).then((res) => {
      if (res.data.series !== 0) {
        this.series = res.data.series;
        this.categories = res.data.categories;
        this.competitions = res.data.competitions;
        this.rankings = res.data.rankings;
        this.changeOption(0);
        this.state = 'ok';
      } else this.state = 'error';
    }).catch((e) => {
      this.state = 'error';
      console.log(e);
    });
  },
  methods: {
    changeOption(option) {
      this.title = this.rankings[option]['name'];
      this.option = option;
    }
  }
};
</script>

<style scoped>
.border-left-light {
  border-left: 1px solid #e0e0e0;
}

.border-left-dark {
  border-left: 1px solid #2f2f2f;
}

.theme--light.v-data-table tbody tr:nth-of-type(odd) {
  background-color: #00000010;
}

.theme--dark.v-data-table tbody tr:nth-of-type(odd) {
  background-color: #ffffff10;
}
</style>
